<template>
  <div>
    <s-crud
      title="Atenciones"
      :filter="filter"
      excel
      edit
      add
      add-no-function
      searchInput
      sortable
      remove
      :config="config"
      @rowSelected="rowSelected($event)"
      @add="add()"
      @edit="edit()"
      @save="saveVeh($event)"
    >
      <template v-slot:filter>
        <div>
          <v-col style="padding-top:0px;padding-bottom:0px;">
            <v-row justify="center">
              <v-col lg="3" class="s-col-form">
                <s-select-area label="Filtro por Área" v-model="filter.AreID">
                </s-select-area>
              </v-col>
              <v-col lg="2" class="s-col-form">
                <s-date
                  label="Fecha Inicio"
                  v-model="filter.BeginDate"
                ></s-date>
              </v-col>
              <v-col lg="2" class="s-col-form">
                <s-date label="Fecha Fin" v-model="filter.EndDate"></s-date>
              </v-col>
              <v-col lg="2" class="s-col-form">
                <s-select-definition
                  v-model="filter.TypeRequirement"
                  :def="1126"
                  label="Tipo de Requerimiento"
                ></s-select-definition>
              </v-col>
              <v-col lg="2" class="s-col-form">
                <s-select-definition
                  v-model="filter.ReqStatus"
                  :def="1128"
                  label="Estado Requerimiento"
                ></s-select-definition>
              </v-col>
            </v-row>
          </v-col>
        </div>
      </template>
    </s-crud>
    
  </div>
</template>
<script>
import _sRequirement from "@/services/Logistics/RequirementService";
export default {
  components: {},
  data: () => ({
    filter: {
      AreID: 0,
      BeginDate: null,
      EndDate: null,
      TypeRequirement: 3,
      ReqStatus: 0,
    },
    config: {
      service: _sRequirement,
      model: {
        ReqID: "ID",
        ReqDocumentNumber: "string",
        ReqDate: "date",
        CmpName: "string",
        UsrName: "string",
        SecStatus: 1,
      },
      headers: [
        {
          text: "",
          value: "ObjectType",
        },
        {
          text: "Req.",
          value: "ReqDocumentNumber",
        },
        {
          text: "Fech.Solicitud",
          value: "ReqDate",
          sortable: true,
        },
        {
          text: "Campaña",
          value: "CmpName",
        },
        {
          text: "Solicitante",
          value: "UsrName",
          align: "end",
        },
        {
          text: "Área",
          value: "AreName",
        },

        {
          text: "Fecha",
          value: "ReqAuthorizationDate",
        },
        {
          text: "Salida Mercancia",
          value: "DocNumOutput",
          align: "center",
        },
        {
          text: "Comentario",
          value: "ReqObservation",
          align: "center",
        },
        {
          text: "Estado",
          value: "RqStatusName",
        },
      ],
    },
    selected: {},
    loadingMasive:false,
  }),
  created() {},
  methods: {
    rowSelected(item) {
      this.selected = item;
    },
  },
  computed: {},
};
</script>
